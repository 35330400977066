/*
 Highcharts JS v5.0.14 (2017-07-28)
 Exporting module

 (c) 2010-2017 Torstein Honsi

 License: www.highcharts.com/license
*/
(function(n){"object"===typeof module&&module.exports?module.exports=n:n(Highcharts)})(function(n){(function(d){var q=d.each,n=d.pick,k=d.win,p=k.document,a=d.seriesTypes,w=void 0!==p.createElement("a").download;d.setOptions({lang:{downloadCSV:"Download CSV",downloadXLS:"Download XLS",viewData:"View data table"}});d.Chart.prototype.getDataRows=function(){var h=(this.options.exporting||{}).csv||{},b,c=this.xAxis,g={},r=[],m=[],f,l,u=h.dateFormat||"%Y-%m-%d %H:%M:%S",k=h.columnHeaderFormatter||function(e,
f,b){return e instanceof d.Axis?e.options.title&&e.options.title.text||(e.isDatetimeAxis?"DateTime":"Category"):e?e.name+(1<b?" ("+f+")":""):"Category"},t=[];f=0;q(this.series,function(e){var b=e.options.keys||e.pointArrayMap||["y"],h=b.length,r=e.requireSorting,u={},l=d.inArray(e.xAxis,c),a;q(b,function(b){u[b]=e[b+"Axis"]&&e[b+"Axis"].categories||[]});if(!1!==e.options.includeInCSVExport&&!1!==e.visible){d.find(t,function(b){return b[0]===l})||t.push([l,f]);for(a=0;a<h;)m.push(k(e,b[a],b.length)),
a++;q(e.points,function(d,c){c=r?d.x:d.x+"|"+c;var m,k;a=0;g[c]||(g[c]=[],g[c].xValues=[]);g[c].x=d.x;g[c].xValues[l]=d.x;e.xAxis&&"name"!==e.exportKey||(g[c].name=d.name);for(;a<h;)m=b[a],k=d[m],g[c][f+a]=n(u[m][k],k),a++});f+=a}});for(l in g)g.hasOwnProperty(l)&&r.push(g[l]);var a,p,h=[m];for(f=t.length;f--;)a=t[f][0],p=t[f][1],b=c[a],r.sort(function(b,d){return b.xValues[a]-d.xValues[a]}),l=k(b),h[0].splice(p,0,l),q(r,function(e){var c=e.name;c||(b.isDatetimeAxis?(e.x instanceof Date&&(e.x=e.x.getTime()),
c=d.dateFormat(u,e.x)):c=b.categories?n(b.names[e.x],b.categories[e.x],e.x):e.x);e.splice(p,0,c)});return h=h.concat(r)};d.Chart.prototype.getCSV=function(d){var b="",c=this.getDataRows(),a=(this.options.exporting||{}).csv||{},h=a.itemDelimiter||",",m=a.lineDelimiter||"\n";q(c,function(a,l){for(var f,g=a.length,k=d?(1.1).toLocaleString()[1]:".";g--;)f=a[g],"string"===typeof f&&(f='"'+f+'"'),"number"===typeof f&&","===k&&(f=f.toString().replace(".",",")),a[g]=f;b+=a.join(h);l<c.length-1&&(b+=m)});
return b};d.Chart.prototype.getTable=function(d){var b="\x3ctable\x3e\x3cthead\x3e",c=this.getDataRows();q(c,function(c,a){var h=a?"td":"th",f,g,k=d?(1.1).toLocaleString()[1]:".";b+="\x3ctr\x3e";for(g=0;g<c.length;g+=1)f=c[g],"number"===typeof f?(f=f.toString(),","===k&&(f=f.replace(".",k)),b+="\x3c"+h+' class\x3d"number"\x3e'+f+"\x3c/"+h+"\x3e"):b+="\x3c"+h+' class\x3d"text"\x3e'+(void 0===f?"":f)+"\x3c/"+h+"\x3e";b+="\x3c/tr\x3e";a||(b+="\x3c/thead\x3e\x3ctbody\x3e")});return b+="\x3c/tbody\x3e\x3c/table\x3e"};
d.Chart.prototype.fileDownload=function(a,b,c,g){var h,m=((this.options.exporting||{}).csv||{}).url||"http://www.highcharts.com/studies/csv-export/download.php";h=this.options.exporting.filename?this.options.exporting.filename:this.title?this.title.textStr.replace(/ /g,"-").toLowerCase():"chart";k.Blob&&k.navigator.msSaveOrOpenBlob?(a=new k.Blob([c]),k.navigator.msSaveOrOpenBlob(a,h+"."+b)):w?(c=p.createElement("a"),c.href=a,c.download=h+"."+b,this.container.append(c),c.click(),c.remove()):d.post(m,
{data:c,type:g,extension:b})};d.Chart.prototype.downloadCSV=function(){var a=this.getCSV(!0);this.fileDownload("data:text/csv,\ufeff"+encodeURIComponent(a),"csv",a,"text/csv")};d.Chart.prototype.downloadXLS=function(){var a='\x3chtml xmlns:o\x3d"urn:schemas-microsoft-com:office:office" xmlns:x\x3d"urn:schemas-microsoft-com:office:excel" xmlns\x3d"http://www.w3.org/TR/REC-html40"\x3e\x3chead\x3e\x3c!--[if gte mso 9]\x3e\x3cxml\x3e\x3cx:ExcelWorkbook\x3e\x3cx:ExcelWorksheets\x3e\x3cx:ExcelWorksheet\x3e\x3cx:Name\x3eArk1\x3c/x:Name\x3e\x3cx:WorksheetOptions\x3e\x3cx:DisplayGridlines/\x3e\x3c/x:WorksheetOptions\x3e\x3c/x:ExcelWorksheet\x3e\x3c/x:ExcelWorksheets\x3e\x3c/x:ExcelWorkbook\x3e\x3c/xml\x3e\x3c![endif]--\x3e\x3cstyle\x3etd{border:none;font-family: Calibri, sans-serif;} .number{mso-number-format:"0.00";} .text{ mso-number-format:"@";}\x3c/style\x3e\x3cmeta name\x3dProgId content\x3dExcel.Sheet\x3e\x3cmeta charset\x3dUTF-8\x3e\x3c/head\x3e\x3cbody\x3e'+
this.getTable(!0)+"\x3c/body\x3e\x3c/html\x3e";this.fileDownload("data:application/vnd.ms-excel;base64,"+k.btoa(unescape(encodeURIComponent(a))),"xls",a,"application/vnd.ms-excel")};d.Chart.prototype.viewData=function(){this.dataTableDiv||(this.dataTableDiv=p.createElement("div"),this.dataTableDiv.className="highcharts-data-table",this.renderTo.parentNode.insertBefore(this.dataTableDiv,this.renderTo.nextSibling));this.dataTableDiv.innerHTML=this.getTable()};var v=d.getOptions().exporting;v&&(d.extend(v.menuItemDefinitions,
{downloadCSV:{textKey:"downloadCSV",onclick:function(){this.downloadCSV()}},downloadXLS:{textKey:"downloadXLS",onclick:function(){this.downloadXLS()}},viewData:{textKey:"viewData",onclick:function(){this.viewData()}}}),v.buttons.contextButton.menuItems.push("separator","downloadCSV","downloadXLS","viewData"));a.map&&(a.map.prototype.exportKey="name");a.mapbubble&&(a.mapbubble.prototype.exportKey="name");a.treemap&&(a.treemap.prototype.exportKey="name")})(n)});
